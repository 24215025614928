<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Secrets</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="loadSecrets()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
        <secret-create></secret-create>
      </div>
    </v-layout>
    <v-layout>
      <page-subtitle
        >View, create and manage your application secrets. Use secrets for any
        sensitive configuration data that you need to supply to your
        applications without adding them to the deployment manifest.
      </page-subtitle>
    </v-layout>

    <v-layout>
      <v-alert
        dense
        border="left"
        type="warning"
        outlined
        v-if="project != undefined && project.secretCount >= project.quota.secrets"
      >
        You have reached your secrets quota. To create new secret either delete
        an existing one or contact support to increase your project's quota.
      </v-alert>
    </v-layout>

    <!-- Error message -->
    <v-layout>
      <div v-if="error">
        <v-alert type="error" dense outlined>
          {{ error }}
        </v-alert>
      </div>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="removeModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete secret '{{ removingItem.name }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="removeModal = false"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="removeConfirmed(removingItem)"
            class="error white--text"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit modal -->
    <v-dialog
      v-model="editModal"
      :max-width="editingItem.type === 'Environment' ? '500px' : '800px'"
    >
      <v-card>
        <v-card-title>Edit Secret</v-card-title>
        <v-card-text>
          <!-- <form v-on:submit.prevent="editConfirmed(editingItem)"> -->
            <v-text-field
              required
              label="Name"
              outlined
              readonly
              v-model="editingItem.name"
            />
            <v-text-field
              v-if="editingItem.type === 'Environment'"
              required
              label="Value"
              v-model="editingItem.data"
            />
            <div v-else>
            <codemirror                   
              v-model="editingItem.data"
              :options="cmOptions"
            ></codemirror>
            </div>
            <!-- <v-btn v-show="false" hidden type="submit"> </v-btn> -->
          <!-- </form> -->
          <div v-show="error">
            <v-alert type="error" outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="editModal = false"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn v-on:click.native="editConfirmed(editingItem)" class="primary">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!selectedNamespaceId">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">Select Namespace</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Use the right side menu to select a namespace.
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <div v-else-if="!loading && secrets.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Secrets</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Here you will see your added secrets to the currently
                    selected namespace.
                  </page-subtitle>
                </span>
                <v-btn 
                  class="mr-3"
                  outlined
                  color="primary"
                  rel="noopener"
                  target="_blank"
                  href="https://docs.synpse.net/synpse-core/applications/secrets"
                >View examples</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else-if="secrets.length > 0" outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="secrets"
            :search="e3"
          >
            <template v-slot:[`item.type`]="{ item }">
              <span v-if="item.type === 'File'">
                <v-icon small class="mr-1">mdi-paperclip</v-icon>
                {{ item.type }}
              </span>
              <span v-else>
                <v-icon small class="mr-1">mdi-variable</v-icon>
                {{ item.type }}
              </span>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="editIntent(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeIntent(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import SecretCreate from './SecretCreate';


// Editor
import { codemirror } from 'vue-codemirror';

// require styles
import 'codemirror/lib/codemirror.css';

// theme css
import 'codemirror/theme/base16-dark.css';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    SecretCreate,
    codemirror
  },

  data() {
    return {
      tableKey: '',
      e3: null,
      cmOptions: {
        // codemirror options
        tabSize: 4,
        // mode: 'text/x-yaml',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        fixedGutter: false // https://github.com/surmon-china/vue-codemirror/issues/112
      },
      headers: [
        // { text: 'ID', align: 'left', value: 'id', sortable: false },
        { text: 'Name', align: 'left', value: 'name', sortable: true },
        { text: 'Type', value: 'type', align: 'left' },
        { text: 'Version', value: 'version', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      removeModal: false,
      removingItem: {
        id: ''
      },
      editModal: false,
      editingItem: {
        id: '',
        data: ''
      }
    }
  },

  watch: {
    selectedNamespaceId() {
      this.loadSecrets()
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    selectedNamespaceId() {
      return this.$route.params.namespace
    },
    namespaces() {
      return this.$store.state.namespace.namespaces
    },
    loading() {
      return this.$store.state.secret.loading
    },
    secrets() {
      return this.$store.state.secret.secrets
    },
    secret() {
      return this.$store.state.secret.secret
    },
    error() {
      return this.$store.state.secret.error
    },
    project() {
      return this.$store.state.project.project
    }
  },

  mounted() {
    // Loading the project to get the quota
    let q = {
      projectId: this.projectId
    }
    this.$store.dispatch('GetProject', q)
    // Load secrets
    this.loadSecrets()
  },

  methods: {
    loadSecrets() {
      let q = {
        project: this.projectId
      }
      this.$store.dispatch('ListNamespaces', q)

      if (this.selectedNamespaceId || this.$route.params.namespace) {
        let q = {
          projectId: this.projectId,
          namespaceId: this.$route.params.namespace
        }
        this.$store.dispatch('ListSecrets', q)
      }
    },
    removeIntent: function (removing) {
      this.removingItem = removing
      this.removeModal = true
    },

    removeConfirmed: function (removingItem) {
      this.$store.dispatch('DeleteSecret', removingItem).then(() => {
        if (this.error === null) {
          this.loadSecrets()
          this.$store.dispatch('Notify', `Secret '${removingItem.name}' deleted`)
        }
        // closing confirmation modal
        this.removeModal = false
      })
    },
    editIntent: function (editingItem) {    
      this.$store.dispatch('GetSecret', editingItem).then(() => {
        if (this.error === null) {    
          this.editingItem = Object.assign({}, this.secret)
          this.editingItem.data = atob(this.secret.data)

          this.editModal = true
        }
      })
    },

    editConfirmed: function (editingItem) {
      editingItem.data = btoa(editingItem.data)      
      this.$store.dispatch('UpdateSecret', editingItem).then(() => {
        this.loadSecrets()
        // closing confirmation modal
        this.editModal = false
      })
    }
  }

}

</script>
